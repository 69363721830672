exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-section-pricing-and-contact-tsx": () => import("./../../../src/pages/contact-section/PricingAndContact.tsx" /* webpackChunkName: "component---src-pages-contact-section-pricing-and-contact-tsx" */),
  "component---src-pages-first-section-first-section-tsx": () => import("./../../../src/pages/first-section/FirstSection.tsx" /* webpackChunkName: "component---src-pages-first-section-first-section-tsx" */),
  "component---src-pages-frezowanie-tsx": () => import("./../../../src/pages/frezowanie.tsx" /* webpackChunkName: "component---src-pages-frezowanie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offer-offer-tsx": () => import("./../../../src/pages/offer/Offer.tsx" /* webpackChunkName: "component---src-pages-offer-offer-tsx" */),
  "component---src-pages-plazma-tsx": () => import("./../../../src/pages/plazma.tsx" /* webpackChunkName: "component---src-pages-plazma-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */),
  "component---src-pages-second-section-second-section-tsx": () => import("./../../../src/pages/second-section/SecondSection.tsx" /* webpackChunkName: "component---src-pages-second-section-second-section-tsx" */),
  "component---src-pages-spawanie-tsx": () => import("./../../../src/pages/spawanie.tsx" /* webpackChunkName: "component---src-pages-spawanie-tsx" */),
  "component---src-pages-szlifowanie-tsx": () => import("./../../../src/pages/szlifowanie.tsx" /* webpackChunkName: "component---src-pages-szlifowanie-tsx" */),
  "component---src-pages-toczenie-tsx": () => import("./../../../src/pages/toczenie.tsx" /* webpackChunkName: "component---src-pages-toczenie-tsx" */)
}

